import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap'; // Import ProgressBar from react-bootstrap

const ExtraInfo = () => {
  // const [additionalInfo, setAdditionalInfo] = useState('');
  const [additionalInfo,setAdditionalInfo]= useState(localStorage.getItem('storedAdditionalInfo')||'')
  const navigate = useNavigate();



  const goBack = () => {
    // Go back one step in the history stack
    navigate(-1);
  };
  const location = useLocation();
  const bodyData = location.state && location.state.bodyData;
  const apiUrl = process.env.REACT_APP_API_URL;
  const handleSubmit = async () => {
    try {
      console.log(apiUrl,"apiurl");
      // Make a POST request to your backend API with both bodyData and additionalInfo
      const response = await axios.post(`${apiUrl}/form/addExtraInfo`, {
        bodyData,
        additionalInfo,
      },{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'http://3.90.248.151:9047', // Replace http://3.90.248.151:9047 with the domain you're making the request from
          // You might need to include other CORS headers as well depending on your setup
        }
      });

      // If the request is successful, navigate to the next page
      if (response.status === 200) {
        // Navigate to the next page (if needed)
        navigate('/requirements', { state: { bodyData: response.data.body }});
      } else {
        // Handle other cases or navigate to another page if needed
      }
    } catch (error) {
      // Handle any errors that occurred during the API request
      console.error('Error submitting extra info:', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(()=>{
    localStorage.setItem('storedAdditionalInfo',additionalInfo)
  },[additionalInfo])

  return (
    <div>
      <div>
        <header>
          <div className="logo">
            <img src="AuRatio_Logo_real_gold_white-grey.png" className="logo" alt="Logo" />
          </div>
          </header>
          <ProgressBar className="ProgressBar" now={calculateProgress()} />
        <div className="nav-container">
         <img src="navimage.jpg" className="nav-image" alt="navimage" />
        </div>
          
        <img
          src="left.png"
          alt="Go Back"
          className="backButton"
          onClick={goBack}
          style={{ cursor: 'pointer', width: '35px', height: '35px', marginRight: '10px' }}
        />

        <h1>Extra Info Extravaganza</h1>
        <p>Unleash the Quirkiness of Your Business</p>
        <div className="form-container infoContainer ">
          <textarea
            id="additionalInfo"
            name="additionalInfo"
            placeholder="Please describe here"
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            onKeyPress={handleKeyPress}
            required
          />
          <button onClick={handleSubmit} type="button">
            Next
          </button>
        </div>
      </div>
    </div>
  );
  function calculateProgress() {
    let progress = 70;
    if (additionalInfo.trim() !== '') progress += 5;
    // if (lastName.trim() !== '') progress += 5;
    return progress;
  }
};

export default ExtraInfo;
