import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap'; // Import ProgressBar from react-bootstrap

const apiUrl = process.env.REACT_APP_API_URL;

const BusinessSize = () => {
  // const [businessSize, setBusinessSize] = useState('');
  const[businessSize,setBusinessSize]=useState(localStorage.getItem('storedBusinessSize')||'')
  const [errorMessage, setErrorMessage] = useState('');
   const navigate = useNavigate();



  const goBack = () => {
    // Go back one step in the history stack
    navigate(-1);
  };
  const location = useLocation();
  const bodyData = location.state && location.state.bodyData;

  const handleSubmit = async () => {
    try {
      if (!businessSize) {
        setErrorMessage('Business size is required.');
        return;
      }

      // Make a POST request to your backend API with both bodyData and businessSize
      const response = await axios.post(`${apiUrl}/form/addBusinessSize`, {
        bodyData,
        businessSize,
      },{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'http://3.90.248.151:9047', // Replace http://3.90.248.151:9047 with the domain you're making the request from
          // You might need to include other CORS headers as well depending on your setup
        }
      });

      // If the request is successful, navigate to the next page
      if (response.status === 200) {
        // Navigate to the next page (if needed)
        navigate('/extrainfo', { state: { bodyData: response.data.body } });
      } else {
        // Handle other cases or navigate to another page if needed
      }
    } catch (error) {
      // Handle any errors that occurred during the API request
      console.error('Error submitting business size:', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(()=>{
    localStorage.setItem('storedBusinessSize',businessSize)
  },[businessSize])

  return (
    <div>
      <div>
        <header>
          <div className="logo">
            <img src="AuRatio_Logo_real_gold_white-grey.png" className="logo" alt="Logo" />
          </div>
          </header>
          <ProgressBar className="ProgressBar" now={calculateProgress()} />
        <div className="nav-container">
         <img src="navimage.jpg" className="nav-image" alt="navimage" />
        </div>
          
        <img
          src="left.png"
          alt="Go Back"
          className="backButton"
          onClick={goBack}
          style={{ cursor: 'pointer', width: '35px', height: '35px', marginRight: '10px' }}
        />

        <h1>Size Matters... in Business!</h1>
        <p>Gauge Your Business's Cosmic Dimensions</p>
        <div className="form-container">
          <select
            id="businessSize"
            name="businessSize"
            className="dropdown selectBussinessSize"
            value={businessSize}
            onChange={(e) => {
              setBusinessSize(e.target.value);
              if (errorMessage && e.target.value) {
                setErrorMessage('');
              }
            }}
            onKeyPress={handleKeyPress} // Add key press event listener
            required
          >
            <option value="" disabled>
              Select Business Size
            </option>
            <option value="1-10">1 — 10</option>
            <option value="11-50">11 — 50</option>
            <option value="51-100">51 — 100</option>
            <option value="101-500">101 — 500</option>
            <option value="500+">500+</option>
            {/* Add more options as needed */}
          </select>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <br />
          <button className="center-button buttonBussinessSize" onClick={handleSubmit} type="button">
            Next
          </button>
        </div>
      </div>
    </div>
  );
  function calculateProgress() {
    let progress = 65;
    if (businessSize.trim() !== '') progress += 5;
    // if (lastName.trim() !== '') progress += 5;
    return progress;
  }
};

export default BusinessSize;
