import React, { useState,useEffect  } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../Email.css'; // Import the CSS file
import { ProgressBar } from 'react-bootstrap'; // Import ProgressBar from react-bootstrap

const apiUrl = process.env.REACT_APP_API_URL;

const Email = () => {
  const [email, setEmail] = useState(localStorage.getItem('storedEmail') || ''); // Get email from local storage or set to empty string
  const [errorMessage, setErrorMessage] = useState('');
   const navigate = useNavigate();



  const goBack = () => {
    // Go back one step in the history stack
    navigate(-1);
  };

  const handleSubmit = async () => {
    try {
      // Check if email is valid
      if (!validateEmail(email)) {
        setErrorMessage('Please enter a valid email address');
        return;
      }

      // Make a POST request to your backend API
      try {
        var response = await axios.post(`${apiUrl}/form/addemail`, { email }, {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'https://3.90.248.151:9047', // Replace http://3.90.248.151:9047 with the domain you're making the request from
            // You might need to include other CORS headers as well depending on your setup
          }
        });
        console.log('Response:', response.data);
      } catch (error) {
        console.error('Error:', error);
      }

      // If the request is successful, navigate to the next page (FullName page)
      if (response.status === 200) {
        console.log(response.data.body, "body data");
        // navigate('/fullname');
        navigate('/fullname', { state: { bodyData: response.data.body } });
      }
    } catch (error) {
      // Handle any errors that occurred during the API request
      console.error('Error submitting email:', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  useEffect(() => {
    // Store email in local storage whenever it changes
    localStorage.setItem('storedEmail', email);
  }, [email]);

  return (
    <div>
      <div>
        <header>
          <div className="logo">
            <img src="AuRatio_Logo_real_gold_white-grey.png" className="logo" alt="Logo" />
          </div>
          </header>
          <ProgressBar className="ProgressBar" now={calculateProgress()} />
        
        <div className="nav-container">
         <img src="navimage.jpg" className="nav-image" alt="navimage" />
        </div>

        <div className="header-content">
        <h1>Email Extravaganza</h1>
        <p>Your Inbox's VIP Pass</p>
        </div>
        <div className="form-container">
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrorMessage('');
            }}
            onKeyPress={handleKeyPress}
            required
            className="my-input"
          />
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <button onClick={handleSubmit} type="button">
            Next
          </button>
        </div>
      </div>
    </div>
  );
  function calculateProgress() {
    let progress = 0;
    if (email.trim() !== '') progress += 10;
    // if (lastName.trim() !== '') progress += 5;
    return progress;
  }
};

export default Email;
