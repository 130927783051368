import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Email from './components/email';
import Address from './components/address';
import Contact from './components/contact';
import Extrainfo from './components/extrainfo';
import ClientRequirements from './components/clientRequirements';
import BusinessSize from './components/bussinessSize';
import Business from './components/bussiness';
import AdditionalInfo from './components/additionalinfo';
import Additionalneeds from './components/additionalneeds';
import Availability from './components/availability';
import FullName from './components/fullName';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/email" element={<Email />} />
          <Route path="/" element={<Email />} />
          <Route path="/fullname" element={<FullName />} />
          <Route path="/address" element={<Address />} />
          <Route path="/contact" element={<Contact/>}/>
          <Route path='/extrainfo' element={<Extrainfo/>}/>
          <Route path="/requirements" element={<ClientRequirements />} />
          <Route path="/businessSize" element={<BusinessSize />} />
          <Route path="/business" element={<Business />} />
          <Route path="/additionalInfo" element={<AdditionalInfo />} />
          <Route path='/additionalNeeds' element={<Additionalneeds/>}/>
          <Route path='/availablity' element ={<Availability/>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
