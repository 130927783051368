import React, { useState ,useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap'; // Import ProgressBar from react-bootstrap

const apiUrl = process.env.REACT_APP_API_URL;

const Business = () => {
  // const [businessName, setBusinessName] = useState('');
  const [businessName ,setBusinessName]=useState(localStorage.getItem('storedBussinessName')||"")
  const [errorMessage, setErrorMessage] = useState('');
   const navigate = useNavigate();



  const goBack = () => {
    // Go back one step in the history stack
    navigate(-1);
  };

  const location = useLocation();
  const bodyData = location.state && location.state.bodyData;

  const handleSubmit = async () => {
    try {
      if (!businessName) {
        setErrorMessage('Business name is required.');
        return;
      }

      // Make a POST request to your backend API with business name
      const response = await axios.post(`${apiUrl}/form/addbusiness`, {
        businessName,
        bodyData,
      },{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'http://3.90.248.151:9047', // Replace http://3.90.248.151:9047 with the domain you're making the request from
          // You might need to include other CORS headers as well depending on your setup
        }
      });

      // If the request is successful, navigate to the next page
      if (response.status === 200) {
        // Navigate to the next page (if needed)
        navigate('/businessSize', { state: { bodyData: response.data.body } });
      } else {
        // Handle other cases or navigate to another page if needed
      }
    } catch (error) {
      // Handle any errors that occurred during the API request
      console.error('Error submitting business information:', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(()=>{
    localStorage.setItem('storedBussinessName',businessName)
  },[businessName])

  return (
    <div>
      <div>
        <header>
          <div className="logo">
            <img src="AuRatio_Logo_real_gold_white-grey.png" className="logo" alt="Logo" />
          </div>
          </header>
          <ProgressBar className="ProgressBar" now={calculateProgress()} />
        <div className="nav-container">
         <img src="navimage.jpg" className="nav-image" alt="navimage" />
        </div>
          
        <img
          src="left.png"
          alt="Go Back"
          className="backButton"
          onClick={goBack}
          style={{ cursor: 'pointer', width: '35px', height: '35px', marginRight: '10px' }}
        />

        <h1>Business Bonanza</h1>
        <p>Unveil the Name Behind the Magic</p>
        <div className="form-container">
          <input
            type="text"
            id="businessName"
            name="businessName"
            placeholder="Business Name"
            value={businessName}
            onChange={(e) => {
              setBusinessName(e.target.value);
              if (errorMessage && e.target.value) {
                setErrorMessage('');
              }
            }}
            onKeyPress={handleKeyPress} // Add key press event listener
            required
          />
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <button onClick={handleSubmit} type="button">
            Next
          </button>
        </div>
      </div>
    </div>
  );
  function calculateProgress() {
    let progress = 60;
    if (businessName.trim() !== '') progress += 5;
    // if (lastName.trim() !== '') progress += 5;
    return progress;
  }
};

export default Business;
