import React, { useState ,useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import "../Contact.css"
import 'react-phone-number-input/style.css';
import { ProgressBar } from 'react-bootstrap'; // Import ProgressBar from react-bootstrap

const apiUrl = process.env.REACT_APP_API_URL;

const Contact = () => {
  const location = useLocation();
  const bodyData = location.state && location.state.bodyData;

  // const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumber, setPhoneNumber]= useState(localStorage.getItem('storedPhoneNumber')|| '')
  const [country, setCountry]=useState(localStorage.getItem('storedCountry')||'')

  const [errorMessage, setErrorMessage] = useState('');
   const navigate = useNavigate();



  const goBack = () => {
    // Go back one step in the history stack
    navigate(-1);
  };

  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
  };

  const handleCountryChange = (newCountry) => {
    setCountry(newCountry);
  };

  const handleSubmit = async () => {
    try {
      // Validate phone number
      if (!phoneNumber) {
        setErrorMessage('Phone number is required.');
        return;
      }

      if (!isValidPhoneNumber(phoneNumber)) {
        setErrorMessage('Please enter a valid phone number.');
        return;
      }

      // Extract country code
      let countryCode = '';
      if (country) {
        countryCode = country;
      }

      // Make a POST request to your backend API with body data, phone number, and country code
      const response = await axios.post(`${apiUrl}/form/addcontact`, {
        bodyData,
        phoneNumber,
        countryCode,
      },{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'http://3.90.248.151:9047', // Replace http://3.90.248.151:9047 with the domain you're making the request from
          // You might need to include other CORS headers as well depending on your setup
        }
      });

      // If the request is successful, navigate to the next page
      if (response.status === 200) {
        // Navigate to the next page (if needed)
        navigate('/business', { state: { bodyData: response.data.body } });
      } else {
        // Handle other cases or navigate to another page if needed
      }
    } catch (error) {
      // Handle any errors that occurred during the API request
      console.error('Error submitting contact information:', error);
    }
  };


  useEffect(()=>{
    localStorage.setItem('storedPhoneNumber',phoneNumber);
    localStorage.setItem('storedCountry',country);
  },[phoneNumber,country])


  return (
    <div>
      <div>
        <header>
          <div className="logo">
            <img src="AuRatio_Logo_real_gold_white-grey.png" className="logo" alt="Logo" />
          </div>
          </header>
          <ProgressBar className="ProgressBar" now={calculateProgress()} />
        <div className="nav-container">
         <img src="navimage.jpg" className="nav-image" alt="navimage" />
        </div>
          
        <img
          src="left.png"
          alt="Go Back"
          className="backButton"
          onClick={goBack}
          style={{ cursor: 'pointer', width: '35px', height: '35px', marginRight: '10px' }}
        />

        <h1>Contact Coordinates</h1>
        <p>Phone Number for Swift Communication</p>
        <div className="form-container">
          <PhoneInput
            placeholder="Enter phone number"
            value={phoneNumber}
            onChange={handlePhoneChange}
            country={country}
            onCountryChange={handleCountryChange}
            required
          />
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <button onClick={handleSubmit} type="button">
            Next
          </button>
        </div>
      </div>
    </div>
  );
  function calculateProgress() {
    let progress = 40;
    if(phoneNumber)
    if (phoneNumber.trim() !== '') progress += 20;
  
    return progress;
  }
};
export default Contact;
