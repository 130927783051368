import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap'; // Import ProgressBar from react-bootstrap

const Address = () => {
  const location = useLocation();
  const bodyData = location.state && location.state.bodyData;
  // const [address, setAddress] = useState('');
  const [address, setAddress] = useState(localStorage.getItem('storedAddress') || '');

  const [addressError, setAddressError] = useState('');
   const navigate = useNavigate();



  const goBack = () => {
    // Go back one step in the history stack
    navigate(-1);
  };
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Initialize Google Places Autocomplete
    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById('address'),
      { types: ['geocode'] }
    );

    // Listen for the 'place_changed' event to get the selected place details
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        console.log('No details available for input: ', place.name);
        return;
      }
      setAddress(place.formatted_address);
    });
  }, []); // Run this effect only once after the component mounts

  const handleSubmit = async () => {
    try {
      // Validate address
      if (!address.trim()) {
        setAddressError('Address is required');
        return;
      }

    

      // Make a POST request to your backend API with body data and address
      const response = await axios.post(`${apiUrl}/form/addaddress`, {
        bodyData,
        address,
      },{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'http://3.90.248.151:9047', // Replace http://3.90.248.151:9047 with the domain you're making the request from
          // You might need to include other CORS headers as well depending on your setup
        }
      });

      // If the request is successful, navigate to the next page
      if (response.status === 200) {
        // Navigate to the next page (if needed)
        navigate('/contact', { state: { bodyData: response.data.body } });
      } else {
        // Handle other cases or navigate to another page if needed
      }
    } catch (error) {
      // Handle any errors that occurred during the API request
      console.error('Error submitting address:', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission when Enter key is pressed
      handleSubmit();
    }
  };

  useEffect(()=>{
    localStorage.setItem('storedAddress',address);
  },[address]);
 
  return (
    <div>
      <div>
        <header>
          <div className="logo">
            <img src="AuRatio_Logo_real_gold_white-grey.png" className="logo" alt="Logo" />
          </div>
          </header>
          <ProgressBar className="ProgressBar" now={calculateProgress()} />
        <div className="nav-container">
         <img src="navimage.jpg" className="nav-image" alt="navimage" />
        </div>
          
        <img
          src="left.png"
          alt="Go Back"
          className="backButton"
          onClick={goBack}
          style={{ cursor: 'pointer', width: '35px', height: '35px', marginRight: '10px' }}
        />

        <h1>Address Adventure</h1>
        <p>Where Your Business Calls Home</p>
        <div className="form-container">
          <input
            type="text"
            id="address"
            name="address"
            placeholder="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            onKeyPress={handleKeyPress} // Handle form submission on Enter key press
            required
          />
          {addressError && <div className="error-message">{addressError}</div>}
          <button onClick={handleSubmit} type="button">
            Next
          </button>
        </div>
      </div>
    </div>
  );
  function calculateProgress() {
    let progress = 20;
    if (address.trim() !== '') progress += 5;
    // if (lastName.trim() !== '') progress += 5;
    return progress;
  }
};

export default Address;
