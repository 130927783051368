import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap'; // Import ProgressBar from react-bootstrap

const apiUrl = process.env.REACT_APP_API_URL;

const Form = () => {
  const location = useLocation();
  const bodyData = location.state && location.state.bodyData;

  // State for selected requirements and error message
  let dummyRequirements=["Website-Wonderland", "SEO-Safari", "Graphic-Designing-Creativity", "Digital-Marketing-Mayhem", "Databases-Discovery", "E-commerce-Expedition", "Mobile-Apps-Odyssey"]
  // const [requirements, setRequirements] = useState([]);
  const [requirements, setRequirements] = useState(JSON.parse(localStorage.getItem('storedRequirements')) || []);


  const [errorMessage, setErrorMessage] = useState('');

   const navigate = useNavigate();



  const goBack = () => {
    // Go back one step in the history stack
    navigate(-1);
  };

  const handleCheckboxChange = (requirement) => {
    // Check if the requirement is already selected, if so, remove it, else add it
    if (requirements.includes(requirement)) {
      setRequirements(requirements.filter(item => item !== requirement));
    } else {
      // Clear error message when a checkbox is selected
      setErrorMessage('');
      setRequirements([...requirements, requirement]);
    }
  };
  

  const handleSubmit = async () => {
    try {
      if (requirements.length === 0) {
        setErrorMessage('Please select at least one requirement.');
        return;
      }

      // console.log(requirements,"here are the selected requriements<<<<<<");return

      // Make a POST request to your backend API with body data, name, and selected requirements
      const response = await axios.post(`${apiUrl}/form/addClientRequirements`, {
        bodyData,
        requirements,
      },{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'http://3.90.248.151:9047', // Replace http://3.90.248.151:9047 with the domain you're making the request from
          // You might need to include other CORS headers as well depending on your setup
        }
      });

      // If the request is successful, navigate to the next page
      if (response.status === 200) {
        // Navigate to the next page (if needed)
        navigate('/additionalNeeds', { state: { bodyData: response.data.body } });
      } else {
        // Handle other cases or navigate to another page if needed
      }
    } catch (error) {
      // Handle any errors that occurred during the API request
      console.error('Error submitting form data:', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(()=>{
    localStorage.setItem('storedRequirements',JSON.stringify(requirements))
  },[requirements])

  useEffect(() => {
    // Update the checked state of checkboxes when requirements change
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach(checkbox => {
      checkbox.checked = requirements.includes(checkbox.value);
    });
  }, [requirements]);

  return (
    <div>
      <div>
      <header>
          <div className="logo">
            <img src="AuRatio_Logo_real_gold_white-grey.png" className="logo" alt="Logo" />
          </div>
          </header>
          <ProgressBar className="ProgressBar" now={calculateProgress()} />
     
          {/*  */}
        <img
          src="left.png"
          alt="Go Back"
          className="backButton"
          onClick={goBack}
          style={{ cursor: 'pointer', width: '35px', height: '35px', marginRight: '10px' }}
        />

        <div className="nav-container">
         <img src="navimage.jpg" className="nav-image" alt="navimage" />
        </div>
          
        <img
          src="left.png"
          alt="Go Back"
          className="backButton"
          onClick={goBack}
          style={{ cursor: 'pointer', width: '35px', height: '35px', marginRight: '10px' }}
        />

        <h1 style={{ marginBottom: '20px' }}>Client Wish Carousel</h1>
        <p style={{ marginBottom: '20px' }}>Select Your Digital Dreams!</p>

        {/* Checkboxes for requirements */}
        <div className="form-container requirementsContainer contact">
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <label>
            <input
              type="checkbox"
              id="webDev"
              name="requirements"
              defaultValue="Website-Wonderland"
              onChange={() => handleCheckboxChange('Website-Wonderland')}
              onKeyPress={handleKeyPress}
            />
            <span className="checkmark" />
            Website Development
          </label>
          <label>
            <input
              type="checkbox"
              id="seo"
              name="requirements"
              defaultValue="SEO-Safari"
              onChange={() => handleCheckboxChange('SEO-Safari')}
              onKeyPress={handleKeyPress}
            />
            <span className="checkmark" />
            Search Engine Optimization (SEO) 
          </label>
          <label>
            <input
              type="checkbox"
              id="graphicDesign"
              name="requirements"
              defaultValue="Graphic-Designing-Creativity"
              onChange={() => handleCheckboxChange('Graphic-Designing-Creativity')}
              onKeyPress={handleKeyPress}
            />
            <span className="checkmark" />
            Graphic Design
          </label>
          <label>
            <input
              type="checkbox"
              id="digitalMarketing"
              name="requirements"
              defaultValue="Digital-Marketing-Mayhem"
              onChange={() => handleCheckboxChange('Digital-Marketing-Mayhem')}
              onKeyPress={handleKeyPress}
            />
            <span className="checkmark" />
            Digital Marketing
          </label>
          <label>
            <input
              type="checkbox"
              id="databases"
              name="requirements"
              defaultValue="Databases-Discovery"
              onChange={() => handleCheckboxChange('Databases-Discovery')}
              onKeyPress={handleKeyPress}
            />
            <span className="checkmark" />
            Databases Development
          </label>
          <label>
            <input
              type="checkbox"
              id="eCommerce"
              name="requirements"
              defaultValue="E-commerce-Expedition"
              onChange={() => handleCheckboxChange('E-commerce-Expedition')}
              onKeyPress={handleKeyPress}
            />
            <span className="checkmark" />
            E-commerce Development
          </label>
          <label>
            <input
              type="checkbox"
              id="mobileApps"
              name="requirements"
              defaultValue="Mobile-Apps-Odyssey"
              onChange={() => handleCheckboxChange('Mobile-Apps-Odyssey')}
              onKeyPress={handleKeyPress}
            />
            <span className="checkmark" />
            Mobile Apps Development
          </label>
          {/* Add other checkboxes similarly */}
          <div style={{ marginTop: '20px' }}>
        <button className='requirementsButton' onClick={handleSubmit} type="button">
          Next
        </button>
      </div>
        </div>
      </div>
    </div>
  );
  function calculateProgress() {
    let progress = 75;
    if (requirements.length > 0) {
      // Calculate progress based on the length of the requirements array
      // Adjust the increment as needed
      progress += 5
    }
    // if (lastName.trim() !== '') progress += 5;
    return progress;
  }
};

export default Form;
