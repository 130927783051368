import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap'; // Import ProgressBar from react-bootstrap
const apiUrl = process.env.REACT_APP_API_URL;

const Form = () => {
  const location = useLocation();
  const bodyData = location.state && location.state.bodyData;
  const [firstName, setFirstName] = useState(localStorage.getItem('storedFirstName') || '');
  const [lastName, setLastName] = useState(localStorage.getItem('storedLastName') || '');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const navigate = useNavigate();

  const goBack = () => {
    // Go back one step in the history stack
    navigate(-1);
  };

  const handleSubmit = async () => {
    try {
      // Validate first name
      if (!firstName.trim()) {
        setFirstNameError('First name is required');
        return;
      } else if (!validateName(firstName)) {
        setFirstNameError('First name must contain only alphabetic characters');
        return;
      }

      // Validate last name
      if (!lastName.trim()) {
        setLastNameError('Last name is required');
        return;
      } else if (!validateName(lastName)) {
        setLastNameError('Last name must contain only alphabetic characters');
        return;
      }

      // Make a POST request to your backend API with body data and name
      const response = await axios.post(`${apiUrl}/form/addname`, {
        bodyData,
        firstName,
        lastName,
      } ,{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'https://3.90.248.151:9047', // Replace http://3.90.248.151:9047 with the domain you're making the request from
          // You might need to include other CORS headers as well depending on your setup
        }
      });


      

      // If the request is successful, navigate to the next page
      if (response.status === 200) {
        // Navigate to the next page (if needed)
        navigate('/address', { state: { bodyData: response.data.body } });
      } else {
        // Handle other cases or navigate to another page if needed
      }
    } catch (error) {
      // Handle any errors that occurred during the API request
      console.error('Error submitting form data:', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const validateName = (name) => {
    const re = /^[A-Za-z]+$/; // Regular expression to allow only alphabetic characters
    return re.test(name);
  };

  useEffect(() => {
    // Store first and last name in local storage whenever they change
    localStorage.setItem('storedFirstName', firstName);
    localStorage.setItem('storedLastName', lastName);
  }, [firstName, lastName]);

  return (
    <div>
      <div>
        <header>
          <div className="logo">
            <img src="AuRatio_Logo_real_gold_white-grey.png" className="logo" alt="Logo" />
          </div>
        </header>
        <ProgressBar className="ProgressBar" now={calculateProgress()} />
        <div className="nav-container">
          <img src="navimage.jpg" className="nav-image" alt="navimage" />
        </div>
        {/*  */}
        <img
          src="left.png"
          alt="Go Back"
          className="backButton"
          onClick={goBack}
          style={{ cursor: 'pointer', width: '35px', height: '35px', marginRight: '10px' }}
        />
        <h1>Basic Info Fiesta</h1>
        <p>Full Name, the Star of the Show!</p>
        <div className="form-container" style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', marginBottom: '10px' }}>
            <input
              type="text"
              id="firstName"
              className="my-input-firstName"
              name="First Name"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
                setFirstNameError('');
              }}
              onKeyPress={handleKeyPress}
              required
              style={{ marginRight: '10px' }} // Adjust margin as needed
            />
            <input
              type="text"
              id="lastName"
              className="my-input-lastName"
              name="Last Name"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                setLastNameError('');
              }}
              onKeyPress={handleKeyPress}
              required
              style={{ marginLeft: '10px' }} // Adjust margin as needed
            />
          </div>
          <button className="firstNamebutton" onClick={handleSubmit} type="button">
            Next
          </button>
        </div>
        {lastNameError && <div className="error-message-lastName">{lastNameError}</div>}
        {firstNameError && <div className="error-message-firstName">{firstNameError}</div>}
      </div>
    </div>
  );

  // Calculate progress based on the completion of form fields
  function calculateProgress() {
    let progress = 10;
    if (firstName.trim() !== '') progress += 5;
    if (lastName.trim() !== '') progress += 5;
    return progress;
  }
};

export default Form;
