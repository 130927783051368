import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap'; // Import ProgressBar from react-bootstrap

const apiUrl = process.env.REACT_APP_API_URL;

const AdditionalNeeds = () => {
  const location = useLocation();
  const bodyData = location.state && location.state.bodyData;
  // const [additionalNeeds, setAdditionalNeeds] = useState('');
  const [additionalNeeds, setAdditionalNeeds] = useState(localStorage.getItem('storedAdditionalNeeds')||"")
   const navigate = useNavigate();



  const goBack = () => {
    // Go back one step in the history stack
    navigate(-1);
  };

  const handleSubmit = async () => {
    try {
      // Make a POST request to your backend API with additional needs data
      const response = await axios.post(`${apiUrl}/form/additionalneeds`, {
        additionalNeeds,
        bodyData
      },{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'http://3.90.248.151:9047', // Replace http://3.90.248.151:9047 with the domain you're making the request from
          // You might need to include other CORS headers as well depending on your setup
        }
      });

      if (response.status === 200) {
        // Navigate to the next page (if needed)
        navigate('/availablity', { state: { bodyData: response.data.body } });
      } else {
        // Handle other cases or navigate to another page if needed
      }
      
      // If the request is successful, you can handle it accordingly
      console.log('Response:', response.data);
      // Handle response data or navigate to the next page if needed
    } catch (error) {
      // Handle any errors that occurred during the API request
      console.error('Error submitting additional needs:', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission when Enter key is pressed
      handleSubmit();
    }
  };

  useEffect(()=>{
    localStorage.setItem('storedAdditionalNeeds',additionalNeeds)
  },[additionalNeeds])
  return (
    <div>
      <div>
        <header>
          {/* Your overlapping logo goes here */}
          <div className="logo">
            <img src="AuRatio_Logo_real_gold_white-grey.png" className="logo" alt="Logo" />
          </div>
          </header>
          <ProgressBar className="ProgressBar" now={calculateProgress()} />
        <div className="nav-container">
         <img src="navimage.jpg" className="nav-image" alt="navimage" />
        </div>
          
        <img
          src="left.png"
          alt="Go Back"
          className="backButton"
          onClick={goBack}
          style={{ cursor: 'pointer', width: '35px', height: '35px', marginRight: '10px' }}
        />

        <h1>Additional Needs Circus</h1>
        <p>The More, The Merrier!</p>
        <div className="form-container">
          <textarea
            id="additionalNeeds"
            name="additionalNeeds"
            placeholder="Additional Needs"
            value={additionalNeeds}
            onChange={(e) => setAdditionalNeeds(e.target.value)}
            onKeyPress={handleKeyPress} // Handle form submission on Enter key press

            required
          />
          <button onClick={handleSubmit} type="button">
            Next
          </button>
        </div>
      </div>
    </div>
  );
  function calculateProgress() {
    let progress = 80;
    if (additionalNeeds.trim() !== '') progress += 10;
    // if (lastName.trim() !== '') progress += 5;
    return progress;
  }
};

export default AdditionalNeeds;
