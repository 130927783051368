import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ProgressBar } from 'react-bootstrap'; // Import ProgressBar from react-bootstrap
import '../Availability.css'; // Import CSS file for custom styling

const apiUrl = process.env.REACT_APP_API_URL;

const Availability = () => {
  const location = useLocation();
  const bodyData = location.state && location.state.bodyData;

  const [selectedTimes, setSelectedTimes] = useState(JSON.parse(localStorage.getItem('storedSelectedTime')) || []);

  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const handleCheckboxChange = (time) => {
    setErrorMessage('');
    setSelectedTimes(prevSelectedTimes => {
      if (prevSelectedTimes.includes(time)) {
        return prevSelectedTimes.filter(item => item !== time);
      } else {
        return [...prevSelectedTimes, time];
      }
    });
  };

  const handleSubmit = async () => {
    try {
      if (selectedTimes.length === 0) {
        setErrorMessage('Please select at least one time slot.');
        return;
      }

      const response = await axios.post(`${apiUrl}/form/addAvailability`, {
        bodyData,
        selectedTimes,
      },{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'http://3.90.248.151:9047', // Replace http://3.90.248.151:9047 with the domain you're making the request from
          // You might need to include other CORS headers as well depending on your setup
        }
      });

      if (response.status === 200) {
        await Swal.fire({
          icon: 'success',
          title: 'Thanks for submission!',
          text: 'Redirecting you to www.auratio.us',
          showConfirmButton: false,
          timer: 100,
        });

        window.location.href = 'https://www.auratio.us';
      }
    } catch (error) {
      console.error('Error submitting availability data:', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    localStorage.setItem('storedSelectedTime', JSON.stringify(selectedTimes));
  }, [selectedTimes]);

  return (
    <div>
      <header>
        <div className="logo">
          <img src="AuRatio_Logo_real_gold_white-grey.png" className="logo" alt="Logo" />
        </div>
      </header>
      <ProgressBar className="ProgressBarCompleted" now={calculateProgress()} />
      <div className="nav-container">
        <img src="navimage.jpg" className="nav-image" alt="navimage" />
      </div>

      <img
        src="left.png"
        alt="Go Back"
        className="backButton"
        onClick={goBack}
        style={{ cursor: 'pointer', width: '35px', height: '35px', marginRight: '10px' }}
      />
      

      <h1>Best Time to Connect </h1>
      <p>What is the best time to contact you?</p>

      <div className="form-container availabilityContainer contact">
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <label className='' >
            <input
              type="checkbox"
              id="9to11"
              name="times"
              value="9to11"
              checked={selectedTimes.includes('9am-11am')}
              onChange={() => handleCheckboxChange('9am-11am')}
            />
            <span className="checkmark" />
            
            9 am - 11 am
          </label>
          <label className='' >
            <input
              type="checkbox"
              id="11to1"
              name="times"
              value="11to1"
              checked={selectedTimes.includes('11am-1pm')}
              onChange={() => handleCheckboxChange('11am-1pm')}
            />
            <span className="checkmark" />
            11 am - 1 pm
          </label>
          <label className='' >
            <input
              type="checkbox"
              id="1to3"
              name="times"
              value="1to3"
              checked={selectedTimes.includes('1pm-3pm')}
              onChange={() => handleCheckboxChange('1pm-3pm')}
            />
            <span className="checkmark" />
            1 pm - 3 pm
          </label>
          <label className='' >
            <input
              type="checkbox"
              id="3to5"
              name="times"
              value="3to5"
              checked={selectedTimes.includes('3pm-5pm')}
              onChange={() => handleCheckboxChange('3pm-5pm')}
            />
            <span className="checkmark" />
            3 pm - 5 pm
          </label>

          <strong>
          <p className="ptag">Unleash the Magic – It's Showtime!</p>
        </strong>
          {/* Add other checkboxes similarly */}
          <div style={{ marginTop: '20px' }}>
        <button className='requirementsButton' onClick={handleSubmit} type="button">
        Submit Spectacle
        </button>
      </div>
        </div>
    </div>
  );
  
  function calculateProgress() {
    let progress = 90;
    if (selectedTimes.length > 0) {
      progress += 10;
    }
    return progress;
  }
};

export default Availability;
