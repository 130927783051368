import React, { Component } from 'react'
import '../Form.css';
import { ProgressBar } from 'react-bootstrap'; // Import ProgressBar from react-bootstrap

export default class additionalinfo extends Component {
  render() {
    return (
      <div>
        <div>
  <header>
    {/* Your overlapping logo goes here */}
    <div className="logo">
      <img src="your-logo.png" alt="Logo" />
    </div>
    </header>
        <div className="nav-container">
         <img src="navimage.jpg" className="nav-image" alt="navimage" />
        </div>
        <img
          src="left.png"
          alt="Go Back"
          className="backButton"
          // onClick={goBack}
          style={{ cursor: 'pointer', width: '35px', height: '35px', marginRight: '10px' }}
        />

  <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur a urna ut libero eleifend ullamcorper.</h1>
  <div className="form-container">
    <textarea id="additionalInfo" name="additionalInfo" placeholder="Additional Info" required defaultValue={""} />
    <button type="submit">Next</button>
  </div>
</div>

    </div>
    )
  }
}
